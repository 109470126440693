import * as React from 'react';
// import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import LogoCollection from './LogoCollection';
import PersonLayingStars from '../images/person-laying-stars.jpeg';
import logo from '../images/somno-tech-logo.png';

export default function Hero() {
  return (
    <Box sx={(theme) => ({
        // muted background image of person laying on stars
        backgroundImage: `url(${PersonLayingStars})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.mode === 'light' ? 'primary.light' : 'primary.dark',
        color: theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
        backgroundBlendMode: 'multiply',
    })}>
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
        //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        background: 'rgba(0, 0, 0, 0.1)',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 8 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Box component="img" src={logo} alt="Somno Tech Logo" />
          {/* <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Our latest&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              products
            </Typography>
          </Typography> */}
          <Typography
            textAlign="center"
            color="text.primary"
            component="h2"
            variant="h4"
            sx={{ marginTop: '4rem', alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Good sleep is no longer just a dream.
          </Typography>
          {/* <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={5}
            useFlexGap
            sx={{ pt: 4, width: { xs: '100%', sm: 'auto' } }}
          > */}
            {/* <Box component="img" src={drugFreeIcon} alt="Drug-Free"></Box>
            <Box component="img" src={lightweightIcon} alt="Lightweight"></Box>
            <Box component="img" src={easyToUseIcon} alt="Easy to Use"></Box> */}
            {/* <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Make one of the best decisions for your future today.
            </Typography>
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Invest in your sleep to improve your health.
            </Typography>
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              33% of your life is spent asleep. Make the most of it.
            </Typography> */}

{/* 
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your email address',
              }}
            />
            <Button variant="contained" color="primary">
              Start now
            </Button> */}
          {/* </Stack> */}
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        /> */}
        <LogoCollection />
        <ExpandCircleDownOutlinedIcon sx={{ fontSize: '4rem', color: 'secondary.main' }} />
      </Container>
    </Box>
    </Box>
  );
}
