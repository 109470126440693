import * as React from 'react';
import { Box, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPills, faFeather, faSmile } from '@fortawesome/free-solid-svg-icons';

export default function LogoCollection() {
  const theme = useTheme();

  const iconStyle = {
    width: '50px',
    height: '50px',
    margin: '0 4rem 1rem 4rem',
    color: theme === 'light' ? 'black' : 'white',
  };

  const textStyle = {
    textAlign: 'center',
    color: theme === 'light' ? 'black' : 'white',
  };

  const cardStyle = {
    padding: '1rem',

    // Border that looks like glass and reflects light
    backdropFilter: 'blur(24px)',
    borderRadius: '12px',
    boxShadow: '0 4px 12px 0 rgba(31, 38, 135, 0.37)',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    zIndex: '1',
    alignSelf: 'center',
    // transition: '0.3s',
    // padding: '20px',
    // border: '1px solid black'
  };

  return (
    <Stack
      id="logoCollection"
      direction={{ xs: 'column', sm: 'row' }}
      alignSelf="center"
      spacing={5}
      useFlexGap
      sx={{ pt: 4, mb: 4, width: { xs: '100%', sm: 'auto' }, display: 'flex', justifyContent: 'center' }}
    >
      <Card sx={cardStyle} variant="outlined">
        <FontAwesomeIcon icon={faPills} style={iconStyle} />
        <Typography variant="h5" style={textStyle}>Drug-Free</Typography>
      </Card>
      <Card sx={cardStyle} variant="outlined">
        <FontAwesomeIcon icon={faFeather} style={iconStyle} />
        <Typography variant="h5" style={textStyle}>Lightweight</Typography>
      </Card>
      <Card sx={cardStyle} variant="outlined">
        <FontAwesomeIcon icon={faSmile} style={iconStyle} />
        <Typography variant="h5" style={textStyle}>Simple to Use</Typography>
      </Card>
    </Stack>
  );
}
