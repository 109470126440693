import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import HealingIcon from '@mui/icons-material/Healing';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useTheme } from '@mui/system';
import { Chip } from '@mui/material';

const chipStyles = { display: 'flex', justifyContent: 'center' };

const whiteLogos = [
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Benefits() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box
      id="benefits"
      sx={(theme) => ({
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        // bgcolor: '#06090a',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        // backgroundImage: `url(${WaterAndStars})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: theme.palette.mode === 'light' ? 'primary.light' : 'primary.dark',
        // color: theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
        // backgroundBlendMode: 'multiply',
      })}>
      <Container
        sx={{
          width: { sm: '100%', md: '80%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Upgrade Your Sleep and Downgrade Your Stress
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mt: '1rem' }}>
          Somno Tech enhances...
        </Typography>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<BedtimeIcon />}
              label="Sleep"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<PsychologyIcon />}
              label="Mental Health"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<ZoomInIcon />}
              label="Focus"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<HealingIcon />}
              label="Recovery"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<HourglassEmptyIcon />}
              label="Aging"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<LightbulbIcon />}
              label="Clarity"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<TrendingUpIcon />}
              label="Productivity"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<FavoriteIcon />}
              label="Heart Health"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<BloodtypeIcon />}
              label="Blood Sugar"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={chipStyles}>
            <Chip
              icon={<FitnessCenterIcon />}
              label="Sexual Performance"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
