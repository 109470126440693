import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
// import Pricing from './components/Pricing';
// import Features from './components/Features';
import Benefits from './components/Benefits';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import Product from './components/Product';
import WaterAndStars from './images/water-and-stars.jpeg';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode, setMode] = React.useState('dark');
  // const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        {/* <Features /> */}
        {/* <Divider /> */}
        <Product />
        <Box
          sx={(theme) => ({
            backgroundImage: `url(${WaterAndStars})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // backgroundColor: theme.palette.mode === 'light' ? 'primary.light' : 'primary.dark',
            // color: theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
            backgroundBlendMode: 'multiply',
          })}>
          <Box
            sx={(theme) => ({
              // backgroundColor: theme.palette.mode === 'light' ? 'primary.light' : 'primary.dark',
              // backgroundColor: linearG
              backgroundImage: 'linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,1))',
              // color: theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
              backgroundBlendMode: 'multiply',
            })}>
            <Benefits />
            {/* <Highlights /> */}
            {/* <Pricing /> */}
            <AboutUs />
          </Box>
        </Box>
        <Footer />
      </Box>
      {/* <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      /> */}
    </ThemeProvider>
  );
}